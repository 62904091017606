
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface ITransferHistory {
  memober: string;
  transaction: string;
  pre_acc: string;
  amount: string;
  after_acc: string;
  createdAt: string;
}
interface DateRange {
  start: string;
  end: string;
}
interface Paginated {
  rows: number;
  page: number;
  limit: number;
  data: Array<any>;
}

export default defineComponent({
  name: "game-bet-history",
  components: {
    Datatable,
    DateRangePicker,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const route = useRoute();
    // page variables
    const tableHeader = ref([
      {
        name: t("Agents.Name"),
        key: "agent",
        customslot: true,
      },
      {
        name: t("DashboardSummary.Members"),
        key: "member",
        text: true,
        sortable: true,
      },
      {
        name: t("Game.Game"),
        key: "game",
      },
      {
        name: t("BetHistory.Room"),
        key: "roomno",
        text: true,
      },
      {
        name: t("BetHistory.Round"),
        key: "roomorder",
        text: true,
      },
      {
        name: t("BetHistory.Betposition"),
        key: "betposition",
        customslot: true,
      },
      {
        name: t("Deposit.State"),
        key: "state",
        customslot: true,
      },
      {
        name: t("BetSummary.BetAmount"),
        key: "betamount",
        customslot: true,
      },
      {
        name: t("BetHistory.Winamount"),
        key: "winamount",
        customslot: true,
      },
      {
        name: t("BetHistory.Date"),
        key: "createdAt",
        text: true,
      },
      {
        name: t("ApiManual.DetailInfo"),
        key: "detail",
        customslot: true,
      },
    ]);
    const transactions = [
      { value: "all", label: t("MoneyTransfer.All") },
      { value: "deposit", label: t("MoneyTransfer.DEPOSIT") },
      { value: "withdraw", label: t("MoneyTransfer.WITHDRAW") },
    ];
    const tableData = ref<Array<ITransferHistory>>([]);
    /**
     * Filter
     *
     */
    let games = [
      { value: "all", label: t("MoneyTransfer.All") },
      { value: "MAXI", label: t("Game.CMB") },
    ];

    const states = [
      { value: "all", label: t("MoneyTransfer.All") },
      { value: "pending", label: t("BetHistory.Pending") },
      { value: "completed", label: t("BetHistory.Completed") },
      { value: "cancelled", label: t("BetHistory.Cancelled") },
    ];
    let daterange = {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };
    const setSelectedDate = (date: DateRange) => (daterange = date);
    const search = ref({
      username: "",
      game: "all",
      state: "all",
    });
    /**
     * Pagination
     *
     */
    const rowsCount = ref(0);
    let pageLimit = 50;
    let pageNumber = 1;

    /**
     * Get History
     *
     */
    const getHistory = async () => {
      const results: Paginated = await ApiService.get(
        `/game/bet-history/${search.value.state}?game=${search.value.game}&username=${search.value.username}&start=${daterange.start}&end=${daterange.end}&limit=${pageLimit}&page=${pageNumber}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results.data);
      rowsCount.value = results.rows;
    };
    /**
     * Set Page Limit
     *
     */
    const setPageLimit = (limit: number) => {
      pageLimit = limit;
      // refresh
      getHistory();
    };
    /**
     * Set Page
     *
     */
    const setPage = (page: number) => {
      pageNumber = page;
      // refresh
      getHistory();
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.MemberBetHistory"), []);
      // get history
      getHistory();
    });
    return {
      tableData,
      tableHeader,
      transactions,
      setSelectedDate,
      setPageLimit,
      setPage,
      getHistory,
      games,
      states,
      search,
      rowsCount,
    };
  },
});
